import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"
import cx from "classnames"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { RESILIENCE_TEST, ANSWER_QUESTION, Journey } from "./../gql"
import LayoutContainer from "./../components/layout"
import Throbber from "./../components/throbber"
import bodyIcon from "./../assets/icons/body-test.svg"
import heartIcon from "./../assets/icons/heart-test.svg"
import mindIcon from "./../assets/icons/mind-test.svg"
import spiritIcon from "./../assets/icons/spirit-test.svg"
import { Trans, withTranslation } from "react-i18next"

const ResilienceTest = ({ location }) => {
  const {
    loading: questionsLoading,
    error: questionsError,
    data: questionsData = {
      resilienceTest: {
        questions: [],
      },
    },
  } = useQuery(RESILIENCE_TEST)
  const [answerQuestion] = useMutation(ANSWER_QUESTION)
  const { nodes: allJourneys } = Journey()
  const [pageNumber, setPageNumber] = useState(0)
  const journeyTitleRef = React.useRef(null)
  const allQuestions = questionsData.resilienceTest.questions
  const areAllQuestionsAnswered = allQuestions.every(
    question => question.answer !== null
  )
  const categoriesProgressionOrder = [
    { icon: bodyIcon, title: "Body" },
    { icon: heartIcon, title: "Heart" },
    { icon: mindIcon, title: "Mind" },
    { icon: spiritIcon, title: "Spirit" },
  ]
  const answers = [
    { value: 1, title: "Strongly Disagree", slug: "strongly-disagree" },
    { value: 2, title: "Disagree", slug: "disagree" },
    { value: 3, title: "Neutral", slug: "neutral" },
    { value: 4, title: "Agree", slug: "agree" },
    { value: 5, title: "Strongly Agree", slug: "strongly-agree" },
  ]

  const journeyOrder = [
    "Movement",
    "Nutrition",
    "Recovery",
    "Emotions",
    "Relationships",
    "Vulnerability",
    "Efficiency",
    "Mindset",
    "Meditation",
    "Purpose",
    "Generosity",
    "Presence",
  ]

  useEffect(() => {
    setQuestionsPage()
  }, [questionsLoading])

  useEffect(() => {
    const journeyTitle = journeyOrder[pageNumber]

    for (let index = 0; index < allJourneys.length; index++) {
      if (allJourneys[index].title === journeyTitle) {
        document.body.style.backgroundColor = allJourneys[index].color
        break
      }
    }
  }, [pageNumber])

  useEffect(() => {
    if (areAllQuestionsAnswered && allQuestions.length > 0) {
      navigate("/resilience-result")
    }

    return () => {
      document.body.removeAttribute("style")
    }
  }, [])

  const onNavigationClick = newPageNumber => {
    setPageNumber(newPageNumber)
    journeyTitleRef.current.scrollIntoView()
  }

  const areQuestionsInCategoryComplete = categoryTitle => {
    const categoryQuestions = allQuestions.filter(
      question => question.category.title === categoryTitle
    )
    return categoryQuestions.every(question => question.answer !== null)
  }

  const isCategoryOfCurrentJourneyActive = (categoryTitle, journeyTitle) => {
    const journeyQuestions = allQuestions.filter(
      question => question.journey.title === journeyTitle
    )
    return journeyQuestions.some(
      question => question.category.title === categoryTitle
    )
  }

  const renderProgressionSteps = () => {
    return categoriesProgressionOrder.map(category => (
      <div
        key={category.title}
        className={cx("survey__progress-category", {
          "survey__progress-category--completed": areQuestionsInCategoryComplete(
            category.title
          ),
          "survey__progress-category--active": isCategoryOfCurrentJourneyActive(
            category.title,
            journeyOrder[pageNumber]
          ),
        })}
      >
        <div className="icon icon--small">
          <img src={category.icon} alt={category.title} />
        </div>
        <p className="survey__progress-title navigation-text uppercase">
          {category.title}
        </p>
      </div>
    ))
  }

  const renderRadioInputs = question => {
    return answers.map(answer => (
      <div
        className="form__radio-wrapper"
        key={`${answer.slug}-${question.contentfulId}`}
      >
        <input
          className="form__radio-input"
          type="radio"
          value={answer.value}
          defaultChecked={question.answer === answer.value}
          name={question.contentfulId}
          id={`${answer.slug}-${question.contentfulId}`}
          onChange={event => onAnsweringQuestion(event, question.contentfulId)}
        />
        <label
          className="form__radio-label form__radio-label--block feature-text"
          htmlFor={`${answer.slug}-${question.contentfulId}`}
        >
          {answer.title}
        </label>
      </div>
    ))
  }

  const setQuestionsPage = () => {
    for (let index = 0; index < categoriesProgressionOrder.length; index++) {
      const categoryTitle = categoriesProgressionOrder[index].title
      for (let index = 0; index < allQuestions.length; index++) {
        if (
          allQuestions[index].answer === null &&
          allQuestions[index].category.title === categoryTitle
        ) {
          const journeyTitle = allQuestions[index].journey.title
          const pageIndex = journeyOrder.indexOf(journeyTitle)
          const newPageNumber = pageIndex === -1 ? 0 : pageIndex
          // For some reason, the Recovery questions show up first in the list.
          // So the code here, that normally takes you to the first journey you haven't answered all the questions to, starts you off on the Recovery questions.
          // As a hack, we check if the page you would land on is one of the first 3 - and if so, put you on the first page.
          if (newPageNumber <= 2) return setPageNumber(0)
          return setPageNumber(newPageNumber)
        }
      }
    }
    return setPageNumber(journeyOrder.length - 1)
  }

  const onAnsweringQuestion = (event, contentfulId) => {
    const answer = Number(event.currentTarget.value)

    answerQuestion({
      variables: {
        contentfulId,
        answer,
      },
      update: (store, { data: updatedData }) => {
        const { answerQuestion } = updatedData
        const cache = store.readQuery({ query: RESILIENCE_TEST })

        const data = {
          ...cache,
          resilienceTest: {
            ...cache.resilienceTest,
            questions: cache.resilienceTest.questions.map(question => {
              if (question.contentfulId === answerQuestion.contentfulId) {
                return {
                  ...question,
                  answer: answerQuestion.answer,
                }
              }
              return question
            }),
          },
        }
        store.writeQuery({ query: RESILIENCE_TEST, data })
      },
    })
  }

  const getJourneyQuestions = pageNumber => {
    const journeyTitle = journeyOrder[pageNumber]
    return allQuestions.filter(
      question => question.journey.title === journeyTitle
    )
  }

  if (questionsLoading)
    return (
      <LayoutContainer seoTitle="Resilience test" path={location.pathname}>
        <Throbber loadingText="Loading questions..." relative />
      </LayoutContainer>
    )

  if (questionsError)
    return (
      <LayoutContainer seoTitle="Resilience test" path={location.pathname}>
        <div className="center-align">
          <p className="text">{questionsError.message}</p>
        </div>
      </LayoutContainer>
    )

  if (questionsData.resilienceTest.completed) {
    return (
      <LayoutContainer seoTitle="Resilience test" path={location.pathname}>
        <div className="row">
          <div className="col l6 offset-l3 s12">
            <div className="center-align">
              <h1 className="big-title uppercase no-margin">
              <Trans i18nKey={"resiliencetest.title"} />
               
              </h1>
              <p className="text">
              <Trans i18nKey={"resiliencetest.sub_text"} />
               
              </p>
              <Link className="button button--white-bg" to="/resilience-result">
              <Trans i18nKey={"resiliencetest.result_button"} />
              </Link>
            </div>
          </div>
        </div>
      </LayoutContainer>
    )
  }

  return (
    <LayoutContainer seoTitle="Resilience test" path={location.pathname}>
      <div className="survey">
        <div className="row no-marginbottom">
          <div className="col l6 offset-l3 s12">
            <h1 className="big-title uppercase center-align no-margin">
            <Trans i18nKey={"resiliencetest.title"} />
             
            </h1>
            <div className="survey__progress">{renderProgressionSteps()}</div>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <h2
              className="survey__title subtitle uppercase no-margintop "
              ref={journeyTitleRef}
            >
              {journeyOrder.length > 0 && journeyOrder[pageNumber]}
            </h2>
            {getJourneyQuestions(pageNumber).map(question => (
              <div className="survey__row" key={question.contentfulId}>
                <div className="survey__col">
                  <p className="text no-margin">{question.content}</p>
                </div>
                <div className="survey__col">
                  <form className="form form--flex no-margin">
                    {renderRadioInputs(question)}
                  </form>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="survey__page-navigation">
          <div className="survey__button-wrapper">
            {pageNumber > 0 && (
              <button
                className="button button--white-bg"
                style={{ cursor: "pointer" }}
                onClick={() => onNavigationClick(pageNumber - 1)}
              >
              <Trans i18nKey={"resiliencetest.previous"} />
                
              </button>
            )}
          </div>
          <div className="survey__button-wrapper right-align">
            {pageNumber < journeyOrder.length - 1 ? (
              <button
                className="button button--white-bg"
                style={{ cursor: "pointer" }}
                onClick={() => onNavigationClick(pageNumber + 1)}
              >
              <Trans i18nKey={"resiliencetest.next"} />
                
              </button>
            ) : (
              <button
                className="button button--white-bg"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/resilience-result")}
                disabled={!areAllQuestionsAnswered}
              >
              <Trans i18nKey={"resiliencetest.submit"} />
              
              </button>
            )}
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}

export default withTranslation()(ResilienceTest)
